import React,{useEffect,useState} from 'react'
import './orders.css'
import {getAllOrder, getOrders, getUserOrder} from '../../features/auth/authSlice'
/*import { getOldOrder } from '../../features/auth/authSlice'*/
import { Link,useLocation,useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from 'xlsx';
import { Typeahead } from 'react-bootstrap-typeahead';
const Orders = () => {
  
  const navigate=useNavigate()

    const location = useLocation();
    const [numOrders, setNumOrders] = useState('');

    const queryParams = new URLSearchParams(location.search);
    let page = parseInt(queryParams.get('page')) || 1;
    const dispatch=useDispatch()
    const orderState=useSelector(state=>state?.auth?.orders.orders)
    const orderState1=useSelector(state=>state?.auth?.getOrder)
useEffect(()=>{
  dispatch(getAllOrder({limit:9999999}))
},[dispatch])
    useEffect(()=>{
      dispatch(getOrders({page}))
  
    },[dispatch,page])


    const updateURL = (updateParams) => {
      const searchParams = new URLSearchParams();
    
      if (updateParams.page !== undefined) {
        searchParams.set('page', updateParams.page);
      }
      navigate(`/orders?${searchParams.toString()}`);
    };


    const nextPage=()=>{
      page++;
      updateURL({ page });
      }
      const prevPage=()=>{
        if (page > 1) {
          page--;
          updateURL({ page });
        }
      }
      const paginate=true


      const exportToExcel = () => {
        const ordersToExport = orderState.slice(-numOrders); // Get the last 'numOrders' orders
    
        // Map each order to include only the desired fields
        const formattedOrders = ordersToExport.map(order => ({
          'Order Date': new Date(order.createdAt).toLocaleString('en-GB', { hour12: true }),
          'Order Number': order.orderNumber,
          'First Name': `${order.shippingInfo.firstname} ${order.shippingInfo.lastname}`,
          'Address': `${order.shippingInfo.address}, ${order.shippingInfo.city},${order.shippingInfo.state},${order.shippingInfo.pincode}`,
          'Mobile': order.shippingInfo.phone,
          'Email': order.shippingInfo.email,
          'Final Amount': order.finalAmount,
          'Order Type': order.orderType,
          'State': order.state
        }));
    
        // Convert formatted orders data to Excel format
        const worksheet = XLSX.utils.json_to_sheet(formattedOrders);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');
    
        // Save the workbook as an Excel file
        XLSX.writeFile(workbook, 'orders.xlsx');
      };
      const searchValue=""
      const [productOpt,setProductOpt]=useState([])
      useEffect(() => {
        let data = [];
        for (let index = 0; index < orderState1?.orders?.length; index++) {
          const element = orderState1?.orders[index];
          // Extracting all relevant product details for search          const title = `${normalizeCode(element?.sku)} ${element.title}`;
          const details = `${element.orderNumber} ${element.shippingInfo.firstname} ${element.shippingInfo.phone} ${element.shippingInfo.email}`;
          data.push({ id: index, prod: element?._id, details }); // Include all relevant details
        }
        
        // Sort the data based on relevance to search query
       
        setProductOpt(data);
      }, [orderState1, searchValue]);
      
      // Function to calculate relevance score

      const handleSelectionChange = (selected) => {
        if (selected[0]?.prod) {
          // If a product is selected, navigate to its details page
          navigate(`/orders/${selected[0]?.prod}`);
          dispatch(getUserOrder(selected[0]?.prod));
        }
      };
  return (
    <div className='orders'>
<div className="head">
        <p className="heading">Orders</p>
        <div className="searchs search1">
        <Typeahead
    clearButton


        id="pagination-example"
        onPaginate={() => console.log('Results paginated')}
        onChange={handleSelectionChange}
        minLength={1}
        options={productOpt}
        labelKey={"details"}
        paginate={paginate}
        placeholder="Search For Orders"
        renderMenuItemChildren={(option) => (
          <div>
            <span>{option.details}</span>
          </div>
        )}
      />        </div>
        <div className='down'>
<div>
<input
            type="number"
            value={numOrders}
            onChange={e => setNumOrders(e.target.value)}
          />
          <button onClick={exportToExcel}>Export</button>
</div>
        </div>

      </div>
      <div className="searchs search2">
        <Typeahead
    clearButton


        id="pagination-example"
        onPaginate={() => console.log('Results paginated')}
        onChange={handleSelectionChange}
        minLength={1}
        options={productOpt}
        labelKey={"details"}
        paginate={paginate}
        placeholder="Search For Orders"
        renderMenuItemChildren={(option) => (
          <div>
            <span>{option.details}</span>
          </div>
        )}
      />        </div>
      <div className="product-table">
            {
              orderState?.slice()?.reverse()?.map((item, index)=>{
                return <Link to={`/orders/${item?._id}`} key={index}>
                  <div className="product" >
                    <p className={`state ${item?.orderType==="COD"?"cod":item?.orderType==="Cancelled"?"cancel":"paid"}`}>{item?.orderType}</p>
                  <div className="product-img">
                    <img src={item?.orderItems[0]?.product?.images && item?.orderItems[0]?.product?.images[0]?.url} alt=""/>
                    <div className="detail">
            <p className='number'><span>Order No.</span> #{item?.orderNumber} </p>
            <p style={{display:'flex',alignItems:'center'}}>{item?.tag} {
              item?.orderCalled==="Called"? <span style={{display:"flex",width:'10px',height:'10px',backgroundColor:'green',borderRadius:'50%',marginLeft:'7px'}}></span>:""
            }</p>
                  <p className="time"><span>Date/Time:</span> {new Date(item?.createdAt).toLocaleString('en-GB', { hour12: true })}

</p>
                  <p className='status'><span>Status:</span> {item?.orderStatus}</p>
                  <p className='amount'><span>Amount/Item:</span> &#8377;{item?.finalAmount} / {item?.orderItems?.length} Items</p>
                  
            </div>
                  </div>
                  <p className='name'><span>Name:</span>{item?.shippingInfo?.firstname} {item?.shippingInfo?.lastname}</p>
                  <p className='address'><span>Address:</span>{item?.shippingInfo?.city},{item?.shippingInfo?.state}</p>
                  <p className='phone'><span>Phone:</span>+91 {item?.shippingInfo?.phone}</p>
                 
        
                </div>
                </Link>
              })
            }



              </div>
      
      
     

      <div className="paginate">
        <button onClick={prevPage}>Prev</button>
        <p>{page}</p>
        <button onClick={nextPage}>Next</button>
      </div>
    </div>
  )
}

export default Orders
