import React, { useState,useEffect } from 'react'
import ReactQuill from "react-quill";
import './collection.css'
import Dropzone from "react-dropzone";
import { delImg, uploadImg } from "../../features/upload/uploadSlice";
import { useDispatch, useSelector } from "react-redux";
import {useLocation, useNavigate} from 'react-router-dom'
import { createCollection,getACollection ,resetState, updateACollection,deleteACollection} from '../../features/collection/collectionSlice';
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';

const Collection = () => {
  const user=JSON.parse(localStorage.getItem("user"))

  const [title,setTitle]=useState("")
  const [metaTitle,setMetaTitle]=useState("")
  const [metaDesc,setMetaDesc]=useState("")
  const [handle,setHandle]=useState("")
  const [images,setImages]=useState([])
  const [category,setCategory]=useState("")
  const [status,setStatus]=useState("draft")
  const location = useLocation()
  const getCollectionId = location.pathname.split("/")[2];
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const imgState = useSelector((state) => state.upload.images);
  const collectionState=useSelector((state)=>state?.collection?.singleCollection)
  useEffect(()=>{
    setTitle(collectionState?.title)
      setCategory(collectionState?.category)
      setMetaTitle(collectionState?.metaTitle)
      setMetaDesc(collectionState?.metaDesc)
      setStatus(collectionState?.status)
      setHandle(collectionState?.handle)
      setImages(collectionState?.images)

  },[collectionState])
  useEffect(() => {

    if (getCollectionId !== undefined) {
      dispatch(getACollection(getCollectionId));
    } else {
      dispatch(resetState());
    }
  }, [getCollectionId]);
useEffect(()=>{
    setImages(imgState)
},[imgState])
  const img = [];
  imgState.forEach((i) => {
    img.push({
      public_id: i.public_id,
      url: i.url,
    });
  });


const createaCollection=()=>{
  if(title==="" || handle==="" || category===""){
    toast.info("Please Fill All The Required Fields")
  }
  
   else {
    if (getCollectionId !== undefined) {
      dispatch(updateACollection({id:getCollectionId, data:{
        title:title,
        handle:handle,
        metaTitle:metaTitle,
        metaDesc:metaDesc,
        status:status,
        images:images,
        category:category,
        createdAt:collectionState?.createdAt,
      }
      }))

    }

   else{
    dispatch(createCollection({title:title,
      handle:handle,
      metaTitle:metaTitle,
      metaDesc:metaDesc,
      status:status,
      images:images,
      category:category,
    }))

   }
  }
}
const deleteImg=()=>{
  dispatch(delImg(collectionState?.images && collectionState?.images[0]?.public_id))
  setImages([])
}
const deleteCollection=()=>{
  dispatch(deleteACollection(getCollectionId))
  setTimeout(()=>{
navigate("/collection")
  },1000)
}
  return (
    <div className='collection'>
        <div className="collection-head">
            <p>Create Collection</p>
            <div className="right">
            <button onClick={deleteCollection}>Delete</button>
            <button onClick={createaCollection}>Save</button>
        </div>
        </div>
      <div className="collection-page">
      <div className="category">
        <p>Name</p>
        <input type="text" placeholder='Enter Name' onChange={(e)=>setCategory(e.target.value)} value={category}/>

        </div>
        <div className="handle">
        <p>Handle</p>
              <input type="text" placeholder='Enter Url' onChange={(e)=>setHandle(e.target.value)} value={handle}/>
        </div>
        <div className="handle">
        <p>Title</p>
              <input type="text" placeholder='Enter Title' onChange={(e)=>setTitle(e.target.value)} value={title}/>
        </div>
        
        <div className="image">
        <p>Image</p>
              {
                images?.length===0?
                <Dropzone
                onDrop={(acceptedFiles) => dispatch(uploadImg(acceptedFiles))}
                style={{width:'100px',height:'100x',border:'1px solid grey',borderRadius:'5px'}}
               >
                {({ getRootProps, getInputProps }) => (
                  <section >
                    <div {...getRootProps()} className="img-box-in">
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              :
              <div>
                <img src={images && images[0]?.url} alt={title} />
<p className="delete" onClick={deleteImg}><CloseIcon/></p>
              </div>

              }
              
           
        </div>
        <div className="status">
        <p>Status</p>
                <select name="" id="" onChange={(e)=>setStatus(e.target.value)} value={status}>
                  <option value="draft">Draft</option>
                  <option value="active">Active</option>
                </select>

        </div>
        <div className="metaTitle">
        <p>Meta Title</p>
              <input type="text" placeholder='Enter Meta Title' onChange={(e)=>setMetaTitle(e.target.value)} value={metaTitle}/>
        </div>
        <div className="metaDesc">
        <p>Meta Description</p>
              <textarea placeholder='Enter Meta Description' onChange={(e)=>setMetaDesc(e.target.value)} value={metaDesc}></textarea>

        </div>
        
      </div>
    </div>
  )
}

export default Collection
