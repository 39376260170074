import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUserOrder,getAllOrder} from "../../features/auth/authSlice";
import DateRangeIcon from '@mui/icons-material/DateRange';
import DvrIcon from '@mui/icons-material/Dvr';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const ViewOrder = () => {

  const navigate=useNavigate()
  const [subTotal,setSubTotal]=useState(null)
  const location = useLocation();
  const orderId = location.pathname.split("/")[2];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserOrder(orderId));
  }, [dispatch,orderId]);
  const orderState = useSelector((state) => state?.auth?.singleorder?.orders);

  useEffect (()=> {
    let sum=0;
    for(let index=0; index < orderState?.orderItems?.length; index++){
        sum =sum+(Number(orderState?.orderItems[index]?.quantity) *orderState?.orderItems[index]?.price)
        setSubTotal(sum)
    }
},[orderState])
 


  const orderState1=useSelector(state=>state?.auth?.getOrder)
    useEffect(()=>{
      dispatch(getAllOrder({limit:99999}))
    },[dispatch])
    const countEmailOccurrences = (arrayOfObjects, email) => {
      return arrayOfObjects?.reduce((count, obj) => {
        return obj?.shippingInfo?.email === email ? count + 1 : count;
      }, 0);
    };
  
    // Example usage:
    const arrayOfObjects = orderState1?.orders || []; // Assuming orderItems contains the objects with emails
    const emailToCount = orderState?.shippingInfo?.email || ''; // Assuming shippingInfo contains the email
    const emailCount = countEmailOccurrences(arrayOfObjects, emailToCount);
      // Log the email count to the console

      const [totalOrders,setTotalOrders]=useState(1)
  useEffect(() => {
    setTotalOrders(emailCount)
  }, [emailCount, emailToCount]);




let trackingId = '';
    let trackingLink = '';

    // Ensure the trackingInfo and link exist and is a string
      if (orderState?.trackingInfo?.link && typeof orderState.trackingInfo.link === 'string') {
        // Split the string by commas to separate different parts
        const parts = orderState.trackingInfo.link.split(',');
        if (parts.length > 0) {
            // Attempt to match and extract the desired parts
            trackingId = parts.find(part => part.trim().startsWith('TrackingId'))?.trim();
            trackingLink = parts.find(part => part.trim().startsWith('Tracking Link'))?.trim();
        }
    }
   const [allOrders,setAllOrders]=useState() 
useEffect(()=>{
  const filterOrdersByEmail = () => {
    // Use the filter method to get all orders where the email matches the given email
    const filteredOrders = orderState1?.orders?.filter(order => order?.shippingInfo?.email === orderState?.shippingInfo?.email);
    return filteredOrders;
};
const ordersss=filterOrdersByEmail()
setAllOrders(ordersss)
},[orderState?.shippingInfo?.email,orderState1])


const [showOrderss,setShowOrderss]=useState("none")
const showOrders=()=>{
  if(showOrderss==="none"){
    setShowOrderss("block")
  }else{
    setShowOrderss("none")
  }
}
  return (
    <div className="single-order">
      <p onClick={()=>navigate("/orders")} className="mb-4 title" style={{display:'flex',margin:'10px 0'}}><IoMdArrowRoundBack style={{cursor:'pointer'}}/>Orders</p>
      <div className="order-head">
          <div className="left">
            <p className="number">
                #{orderState?.orderNumber}
            </p>
            <p className={`type ${orderState?.orderType==="COD"?"cod":orderState?.orderType==="Cancelled"?"cancel":"paid"}`} >{orderState?.orderType}</p>
            <p className={`stati ${orderState?.orderStatus==="Ordered"?"status":orderState?.orderStatus==="Fullfilled"?"status1":"status2"}`}>{orderState?.orderStatus}</p>
            <p className="date"><DateRangeIcon className="date-icon"/>{new Date(orderState?.createdAt).toLocaleString('en-GB', { hour12: true })}</p>
          </div>
          
        </div>
      <div className="order-detail">
        
       <div className="left-section">
       <div className="order-products">
        {
          orderState?.orderItems?.map((item,index)=>{
            return( <Link to={`/addProduct/${item?.product?._id}`}>
<div className="prdt">
          <div className="left">
            <img src={item?.product?.images && item?.product?.images[0]?.url} alt="" />
            <div className="details">
              <p>{item?.product?.title}</p>
              <p><span>color:</span>{item?.color}</p>
              <p><span>size:</span>{item?.size}</p>
              <p><span>sku:</span>{item?.product?.sku}</p>
            </div>
          
    <p className="price">&#8377;{item?.price}</p>
    <p className="qty">{item?.quantity}</p>
    <p className="total">&#8377;{(item?.product?.price)*(item?.quantity)}</p>
    </div>
          </div>
          </Link>
            )
          })
        }
          

        </div>
        <div className="payment-info">
            <p className="head">Payment Summary</p>
          <div className="payment">
            <div className="left">
<p>Subtotal</p>
<p>Shipping</p>
<p>Discount</p>
<p>Total</p>
            </div>
            <div className="center">
              <p>{orderState?.orderItems?.length} Item</p>
              <p>{orderState?.orderType}</p>
              <p></p>
              <p></p>
            </div>
            <div className="right">
            <p>&#8377;{subTotal}</p>
              <p>&#8377;{orderState?.shippingCost}</p>
              <p>&#8377;{Math.floor(orderState?.discount)}</p>
              <p>&#8377;{subTotal+(orderState?.shippingCost)-(Math.floor(orderState?.discount))}</p>
            </div>
          </div>
        </div>
       </div>
       <div className="right-section">
        {
          orderState?.trackingInfo &&
          <div className="tracking">
        <p className="right-head">Tracking</p>
        <p className="id">{trackingId}</p>
        <p className="id">{trackingLink}</p>

        <p className="id">Partner: {orderState?.trackingInfo?.partner}</p>


        </div>
        }
        <div className="customer">
        <p className="right-head">Customer</p>
        <p className="customer-name"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXPgCd93aiNba8lHWJYHQ1C1YwBPwcH_NUmw&usqp=CAU" alt="" /><p>{orderState?.shippingInfo?.firstname} {orderState?.shippingInfo?.lastname}</p></p>
        <p className="customer-orders" style={{cursor:'pointer'}} onClick={showOrders}><DvrIcon className="order-icon"/><p className="order-count">{totalOrders} Orders</p></p>
        <div className="alls" style={{display:showOrderss}}>
          {
            allOrders && allOrders?.map((item,index)=>{
             return <p key={index} onClick={()=>navigate(`/orders/${item?._id}`)}>{item?.orderNumber} - &#8377;{item?.finalAmount}</p>
            })
          }
        </div>

        </div>
        <hr style={{opacity:'0.2'}}/>
        <div className="email">
          <p style={{fontWeight:600,fontSize:'13px',marginBottom:'15px'}}>Contact Info</p>
          <p><EmailIcon className="order-icon"/>{orderState?.shippingInfo?.email}</p>
          <p><LocalPhoneIcon className="order-icon"/>+91 {orderState?.shippingInfo?.phone}</p>

        </div>
        <hr style={{opacity:'0.2'}}/>

        <div className="email">
          <p style={{fontWeight:600,fontSize:'13px',marginBottom:'15px'}}>Shipping Address</p>
          <p>{orderState?.shippingInfo?.firstname}</p>
          <p>{orderState?.shippingInfo?.address}</p>
          <p>{orderState?.shippingInfo?.city}</p>
          <p>{orderState?.shippingInfo?.pincode}</p>
          <p>{orderState?.shippingInfo?.state}</p>
          <p>+91 {orderState?.shippingInfo?.phone}</p>
          <p>+91 {orderState?.shippingInfo?.mobile}</p>
          {/* <p>+916387017782</p> */}
        </div>
       </div>
      </div>
      <div>
        <div className="message">
          <div className="history">
          <p style={{marginBottom:'10px'}}>Order History</p>
          {
                orderState?.orderHistory?.map((item)=>{
                  return <div className="history-box">
                  <div className="name">
                          <p>{item?.name}</p>
                          <p className="time">{new Date(item?.time).toLocaleString('en-GB', { hour12: true })}</p>
                        </div>
                        <p className="msg-c">{item?.message}</p>
                  </div>
                })
              }
          
          </div>
        </div>
        
     
        {/* <Table columns={columns} dataSource={data1} /> */}
      </div>
      
    </div>
    
    
  );
};

export default ViewOrder;
