import React, { useState, useEffect } from 'react';
import './products.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProducts, resetState } from '../../features/product/productSlice';

const Products = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let page = parseInt(queryParams.get('page')) || 1;
  let state = queryParams.get('state') || '';

  const [cachedProducts, setCachedProducts] = useState({});
  const productState = useSelector((state) => state?.product?.prdt);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!cachedProducts[`${page}-${state}`]) {
      dispatch(resetState());
      dispatch(getAllProducts({ page, state })).then((data) => {
        setCachedProducts({ ...cachedProducts, [`${page}-${state}`]: data.payload });
      });
    }
  }, [dispatch, page, state, cachedProducts]);
console.log(page)
  const updateURL = (updateParams) => {
    const searchParams = new URLSearchParams();

    if (updateParams.page !== undefined) {
      searchParams.set('page', updateParams.page);
    }

    if (updateParams.state !== undefined) {
      searchParams.set('state', updateParams.state);
    }

    navigate(`/products?${searchParams.toString()}`);
  };

  const nextPage = () => {
    page++;
    updateURL({ page, state });
  };

  const prevPage = () => {
    if (page > 1) {
      page--;
      updateURL({ page, state });
    }
  };

  const clearFilter = () => {
    updateURL({ page: '', state: '' });
  };

  return (
    <div className='products'>
      <div className='head'>
        <p className='heading'>Products</p>
      </div>
      <div className='filter'>
        <button onClick={() => updateURL({ page, state: 'draft' })}>Draft</button>
        <button onClick={() => updateURL({ page, state: 'active' })}>Active</button>
        <button onClick={clearFilter}>Clear Filter</button>
      </div>
      <div className='product-table'>
        {cachedProducts[`${page}-${state}`]?.map((item, index) => {
          return (
            <Link to={`/addProduct/${item?._id}`} key={index}>
              <div className='product'>
                <div className='product-img'>
                  <img src={item?.images && item?.images[0]?.url} alt='' />
                </div>
                <p className='title'>{item.title}</p>
                <p className='sku'>{item.sku}</p>
                <p className='state' style={{ backgroundColor: item?.state === 'active' ? '#28ae2e' : '#ff3f3f' }}>
                  {item?.state}
                </p>
                <p
                  className='inventory'
                  style={{
                    color:
                      item.variants.reduce((total, item) => total + item.quantity, 0) <= 5 ? 'rgb(189, 20, 20)' : 'green',
                  }}
                >
                  {item.variants.reduce((total, item) => total + item.quantity, 0)} available
                </p>
                <p className='category'>{item.category}</p>
              </div>
            </Link>
          );
        })}
      </div>
      <div className='paginate'>
        <button onClick={prevPage} disabled={page === 1 ? true : false} style={{ backgroundColor: page === 1 ? 'grey' : '', cursor: page === 1 ? 'context-menu' : '' }}>
          Prev
        </button>
        <p>{page}</p>
        <button
          onClick={nextPage}
          disabled={productState?.length < 100 ? true : false}
          style={{ backgroundColor: productState?.length < 100 ? 'grey' : '', cursor: productState?.length < 100 ? 'context-menu' : '' }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Products;
