import React,{useEffect,useState} from 'react'

const Testing = () => {

const [p1tsxt,setP1text]=useState("Hello keshav")
const [pcolor,setPcolor]=useState("H")
const [font,setfont]=useState("")
const [fontcolor,setfontcolor]=useState()
const fontchangeclick=()=>{
    setfont(`hello i am a font of my text`)
setfontcolor(`B`)

}
const btn1Click=()=>{
    setP1text(`Hello Keshav the time is: ${Date()}`)
    setPcolor("R")
}
const btn1Click1=()=>{
    setP1text(`Hello Brajendra the time is: ${Date()}`)
    setPcolor("B")

}
const btn1Click2=()=>{
    setP1text(`Hello Ankit the time is: ${Date()}`)
    setPcolor("Bl")

}
  return (
    <div>
      <button onClick={fontchangeclick}>Btn1</button>
      <button onClick={btn1Click1}>Btn2</button>
      <button onClick={btn1Click2}>Btn3</button>


      <p style={{color:fontcolor==="B"?"green":fontcolor==="B"?"green":"red"}}>{setfont}</p>
      <p></p>
      <h1></h1>
      
    </div>
  )
}

export default Testing
