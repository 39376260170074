import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";
const login = async (user) => {
  const response = await axios.post(`${base_url}user/admin-login`, user);
  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};
const getOrders = async (data) => {
  const response = await axios.get(`${base_url}user/getallorders1?limit=50&page=${data.page}`, config);

  return response.data;
};


const getAllOrder = async (data) => {
  const response = await axios.get(`${base_url}user/getallorders1?limit=${data.limit}&page=1`, config);

  return response.data;
};
const getOrder = async (id) => {
  console.log(config)

  const response = await axios.get(
    `${base_url}user/getaOrder/${id}`,
    config
  );

  return response.data;
};


const getMonthlyOrders = async () => {
  const response = await axios.get(
    `${base_url}user/getMonthWiseOrderIncome1`,
    config
  );

  return response.data;
};

const getYearlyStats = async () => {
  const response = await axios.get(
    `${base_url}user/getYearlyTotalOrders1`,
    config
  );

  return response.data;
};
const getTodayOrders = async () => {
  const response = await axios.get(
    `${base_url}user/getTodayTotalOrders1`,
    config
  );

  return response.data;
};
const getYesterdayOrders = async () => {
  const response = await axios.get(
    `${base_url}user/getYesterdayTotalOrders1`,
    config
  );

  return response.data;
};
const getWeekOrders = async () => {
  const response = await axios.get(
    `${base_url}user/getWeekTotalOrders1`,
    config
  );

  return response.data;
};
const getCustomOrders = async (data) => {
  const response = await axios.get(
    `${base_url}user/getCustomTotalOrders1?startDate=${data.startDate}&endDate=${data.endDate}`
  );

  return response.data;
};
const getlastData = async () => {
  const response = await axios.get(
    `${base_url}user/graphData1`
  );

  return response.data;
};

const authService = {
  login,
  getOrders,
  getOrder,
  getMonthlyOrders,
  getYearlyStats,
  getTodayOrders,
  getWeekOrders,
  getYesterdayOrders,
  getAllOrder,
  getCustomOrders,
  getlastData
};

export default authService;
