import React, { useState,useEffect,useRef } from 'react'
import './header.css'
import { getOrders, addOrder } from '../../features/auth/authSlice';
import { useDispatch, useSelector,useStore } from 'react-redux'
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import AppsIcon from '@mui/icons-material/Apps';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import BookIcon from '@mui/icons-material/Book';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import GroupsIcon from '@mui/icons-material/Groups';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import Badge from '@mui/material/Badge';
import {useNavigate,Link, useLocation} from 'react-router-dom'
import {getAProduct, getProducts} from '../../features/product/productSlice'
import { Typeahead } from 'react-bootstrap-typeahead';
import logo from './logo.png'
import CloseIcon from '@mui/icons-material/Close'; // Import the CloseIcon component
import coin from './coin.mp3'
function ListItem({ text, onClick, isSelected ,icon,url}) {
  return (
    <li
      onClick={onClick}
      style={{ backgroundColor: isSelected ? 'rgba(243, 243, 243, 0.863)' : 'transparent' }}
    >
      {icon} <Link to={url}>{text}</Link>
    </li>
  );
}
const Header = () => {
  const [open, setOpen] = useState(true);
const [headClose,setHeadClose]=useState("-100%")
const [productOpt,setProductOpt]=useState([])
const [paginate, setPaginate] = useState(true);
const [liBg,setLiBg]=useState("")
const [selectedItem, setSelectedItem] = useState(null);
const [logOpen,setLogOpen]=useState("none")
const [isHead2Open, setIsHead2Open] = useState(false); // Define isHead2Open state variable
const dispatch=useDispatch()
const audioRef = useRef(null); // Ref for the audio element
const store=useStore()
const orders=useSelector(state=>state?.auth?.orders.orders)

const location=useLocation()
const navigate = useNavigate();
const productState = useSelector((state) => state?.product?.products);

useEffect(() => {
    // Dispatch request for all products only if products haven't been loaded yet
    if (!productState || !productState.length) {
      dispatch(getProducts({ limit: 3000, page: 1 }));
    }
  }, [dispatch, productState]);

const handleItemClick = (index) => {
  setSelectedItem(index);
  setHeadClose("-100%")
  setIsHead2Open(false)
};
const profileClick=()=>{
  if(logOpen==="none"){
    setLogOpen("block")
  }else{
    setLogOpen("none")
  }
}
const user = JSON.parse(localStorage.getItem('user'));


  const handleClick = () => {
    setOpen(!open);
  };
  const hamClick=()=>{
    if(headClose=="-100%"){
      setHeadClose("0")
      setIsHead2Open(true)
    }
    else{
      setHeadClose("-100%")
      setIsHead2Open(false)
    }
  }

  const setHeadClose1=()=>{
    setHeadClose("-100%")
      setIsHead2Open(false)
  }


  const liClick=()=>{
    if(liBg==""){
      setLiBg("red")
    }
    else{
      setHeadClose("")
    }
  }
  const headerRef = useRef(null);

const menuList=[
  {
    name:"Dashboard",
    icon:<DashboardIcon className='liIcon'/>,
    link:"/"
  },
  {
    name:"Orders",
    icon:<CardGiftcardIcon className='liIcon'/>,
    link:"/orders"
  },
  
  {
    name:"Products",
    icon:<Inventory2Icon className='liIcon'/>,
    link:"/products"
  },
  {
    name:"Collections",
    icon:<AppsIcon className='liIcon'/>,
    link:"/collection"
  },
  {
    name:"Blogs",
    icon:<BookIcon className='liIcon'/>,
    link:"/blogs"
  },
  {
    name:"Banners",
    icon:<ViewCarouselIcon className='liIcon'/>,
    link:"/banner"
  },
]

const [imageIndex, setImageIndex] = useState(0);
const [searchValue, setSearchValue] = useState('');
const handleImageError = () => {
  // Increment the image index to load the next image URL
  setImageIndex(prevIndex => prevIndex + 1);
}; 
function normalizeCode(code) {
  // Transform SKU format: Separate digits and letters with single space
  const transformedCode = code
    .replace(/-/g, ' ') // Replace hyphens with a single space
    .replace(/([a-zA-Z])\s*([^a-zA-Z0-9])/g, '$1 '); // Separate digits and letters with single space

  // Remove non-alphanumeric characters and excess whitespace
  const normalizedCode = transformedCode.replace(/[^a-zA-Z0-9\s]/g, '');

  return normalizedCode.toLowerCase(); // Convert to lowercase
}


useEffect(() => {
  let data = [];
  for (let index = 0; index < productState?.length; index++) {
    const element = productState[index];
    // Extracting all relevant product details for search
    const imageUrl =`${element?.images && element?.images[imageIndex]?.url}`
    const title = `${normalizeCode(element?.sku)}`;
    const details = `${element.sku} ${element.title} ${element.description}`;
    data.push({ id: index, prod: element?._id, details, imageUrl, title }); // Include all relevant details
  }
  
  // Sort the data based on relevance to search query
  data.sort((a, b) => {
    const aTitle = a.title.toLowerCase();
    const bTitle = b.title.toLowerCase();
    const searchValueLower = searchValue.toLowerCase();

    // Calculate relevance scores for each item
    const aRelevance = calculateRelevance(aTitle, searchValueLower);
    const bRelevance = calculateRelevance(bTitle, searchValueLower);

    // Sort based on relevance scores
    if (aRelevance !== bRelevance) {
      return bRelevance - aRelevance; // Higher relevance should come first
    } else {
      // If relevance scores are the same, maintain the original order
      return a.id - b.id;
    }
  });

  setProductOpt(data);
}, [productState, searchValue]);

// Function to calculate relevance score
const calculateRelevance = (title, searchValue) => {
  if (title === searchValue) {
    return 3; // Highest relevance for exact matches
  } else if (title.startsWith(searchValue)) {
    return 2; // High relevance for matches at the beginning of the title
  } else if (title.includes(searchValue)) {
    return 1; // Moderate relevance for matches anywhere in the title
  } else {
    return 0; // Lowest relevance for no matches
  }
};
useEffect(() => {
  const handleClickOutside = (event) => {
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      setHeadClose("-100%");
      setIsHead2Open(false)

    }
  };

  document.addEventListener("mousedown", handleClickOutside);

  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);
  
const handleClearSearch = () => {
  setSearchValue(''); // Clear the search value
};
const handleClearButtonClick = () => {
  // Redirect to /products
  navigate('/addProduct');
};
const handleSelectionChange = (selected) => {
  if (selected[0]?.prod) {
    // If a product is selected, navigate to its details page
    navigate(`/addProduct/${selected[0]?.prod}`);
    dispatch(getAProduct(selected[0]?.prod));
    setSelectedItem(null);
  }
};

  return (
    <div className='header' ref={headerRef}>
      {isHead2Open && <div className="overlay" onClick={setHeadClose1}></div>}

      <audio ref={audioRef}>
        <source src={coin} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <div className="head1">
        <div className="left">
          <button>
            <MenuIcon onClick={hamClick} style={{cursor:'pointer'}}/>
          </button>
        
          <img src={logo} alt="" className='logo logo1' onClick={()=>navigate("/")} style={{cursor:'pointer'}}/>


        </div>
        <div className="center">
          <div className="search">
<button><SearchIcon/></button>
<Typeahead
    clearButton


        id="pagination-example"
        onPaginate={() => console.log('Results paginated')}
        onChange={handleSelectionChange}
        minLength={1}
        options={productOpt}
        labelKey={"title"}
        paginate={paginate}
        placeholder="Search here"
        renderMenuItemChildren={(option) => (
          <div>
            <img src={option.imageUrl} alt="" style={{marginLeft: '10px' }} onError={handleImageError}/>
            <span>{option.title}</span>
          </div>
        )}
      />
  

          </div>
        </div>
        <div className="right">
<button>
<Badge badgeContent={4} color="primary">
      <NotificationsActiveIcon color="action" />
    </Badge></button>
<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDrmpYNvqOST9I5HZR-ZIwLULW2v0x2g7xOw&usqp=CAU" alt="" onClick={profileClick}/>
<div className="logout" style={{display:logOpen}}>
  <ul>
    <li><AccountCircleIcon className='log-icon'/>Profile</li>
    <li onClick={()=>(localStorage.clear(),navigate("/login"),setLogOpen("none"))}><LogoutIcon className='log-icon'/>Logout</li>
  </ul>
</div>
        </div>
      </div>

      <div className="head2" style={{left:headClose}} >
      <p className='logo logo2' onClick={()=>navigate("/")} style={{cursor:'pointer'}}>Levish Luxury</p>
      <div>
        <ul>
          {
            menuList.map((item,index)=>{
              return <ListItem
              url={item.link}
              icon={item.icon}
              key={index}
              text={item.name}
              
              onClick={() =>( handleItemClick(index),navigate(item.link))}
              isSelected={selectedItem === index}
            >
            </ListItem>
            })
          }



        </ul>
      </div>
      
      </div>
    </div>
  )
}

export default Header
